import React from "react";

const QueueEditableRow = ({ editFormData, handleEditFormChange, handleEditDropDownFormChange, handleCancelClick}) =>
{
    return (
        <tr>
            <td> {editFormData.QueueNo} </td>
            <td> {editFormData.Capacity} </td>
            <td>
                <select onChange={handleEditDropDownFormChange}>
                    <option value="PendingTableNo">PendingTableNo</option>
                    <option value="WaitingForCust">WaitingForCustomer</option>
                    <option value="Seated">Seated</option>
                    <option value="Missed">Missed</option>
                    <option value="Completed">Completed</option>
                </select>
            </td>
            <td>
                <input
                    type="text"
                    name="TableNo"
                    required="required"
                    value={editFormData.TableNo}
                    placeholder="Enter TableNo"
                    onChange={handleEditFormChange}
                />
            </td>
            <td> {editFormData.UserId} </td>
            <td>
                <button type="submit"> Update </button>
                <button type="button" onClick={handleCancelClick}> Cancel </button>
            </td>
        </tr>
    );
};

export default QueueEditableRow