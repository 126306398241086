import React, { useState, Fragment } from "react";
import classes from "./Table.module.css";
import ReadOnlyRow from "./TableReadOnlyRow";
import EditableRow from "./TableEditableRow";

export default function Tables(props) {
  const rows = props.items || [];

  const [tables, setTables] = useState(rows);

  // storing data from tableMockDate.json as data
  //const [tables, setTables] = useState(data);

  //#region handle new row change
  const [addFormData, setAddFormData]=useState({
    TableNo:'',
    Capacity:'',
    TableStatus:''
  })

  const handleAddFormChange = (event) => {
    event.preventDefault();

    const fieldName = event.target.getAttribute('name');
    const fieldValue = event.target.value;

    const newFormData = { ...addFormData };
    newFormData[fieldName]=fieldValue;

    setAddFormData(newFormData);
  }
  //#endregion

  //#region handle new row submit
  const handleAddFormSubmit = (event) => {
    event.preventDefault();

    const newTable = {
      //id: uuidv4(),
      TableNo: addFormData.TableNo,
      Capacity: addFormData.Capacity,
      TableStatus: "Vacant"
    }

    const newTables = [...tables, newTable];
    setTables(newTables);

    props.wssSendEvent(
      "putTable",
      {
        TableNo: addFormData.TableNo,
        Capacity: addFormData.Capacity,
        TableStatus: "Vacant",
      },
      true
    );
  }
  //#endregion

  //#region handle edit Table row
  const [editTableNo, setEditTableNo] = useState(null);

  const handleEditClick = (event, table) => {
    event.preventDefault();
    setEditTableNo(table.TableNo);

    const formValues = {
      TableNo: table.TableNo,
      Capacity : table.Capacity,
      TableStatus : table.TableStatus
    };

    setEditFormData(formValues);
  };

  const [editFormData, setEditFormData]=useState({
    TableNo:'',
    Capacity:'',
    TableStatus:''
  })

  const handleEditFormChange = (event)=>{
    event.preventDefault();

    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;

    const newFormData = {...editFormData};
    newFormData[fieldName] = fieldValue;

    setEditFormData(newFormData);
  }

  const handleEditFormSubmit = (event) => {
    event.preventDefault();

    props.wssSendEvent(
      "putTable",
      {
        TableNo: editFormData.TableNo,
        Capacity: editFormData.Capacity,
        TableStatus: editFormData.TableStatus
      },
      true
    );

    const editedTable = {
      //id: editTableId,
      TableNo: editFormData.TableNo,
      Capacity: editFormData.Capacity,
      TableStatus: editFormData.TableStatus
    }

    const newTables = [...tables];

    const index = tables.findIndex((table) => table.TableNo === editTableNo);

    newTables[index] = editedTable;

    setTables(newTables);
    setEditTableNo(null);
  }
  //#endregion

  const handleCancelClick = () => {
    setEditTableNo(null);
  }

  const handleDeleteClick = (TableNo) =>{
    const newTables = [...tables];
    const index = tables.findIndex((table) => table.TableNo === TableNo);
    let text = "Delete table " + TableNo +" ?";

    if(window.confirm(text))
    {
      props.wssSendEvent(
        "deleteTable",
        {
          TableNo: newTables[index].TableNo,
          Capacity: newTables[index].Capacity,
        },
        true
      );
  
      newTables.splice(index, 1);
      setTables(newTables);
    }
  }

  return (
    <div className={classes.tableList}>
      <h2>Add a table</h2>
      <form onSubmit={handleAddFormSubmit}>
        <input maxlength="4"
          type="text"
          name="TableNo"
          required="required"
          placeholder="Enter table no."
          onChange={handleAddFormChange}
        />
        <input maxlength="2"
          type="text"
          name="Capacity"
          required="required"
          placeholder="Enter table capacity"
          onChange={handleAddFormChange}
        />
        <button type="submit">Add</button>
      </form>
      <form onSubmit={handleEditFormSubmit}>
        <table>
          <thead>
            <tr>
              <th>Table No. </th>
              <th>Capacity</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {tables.map((table) => (
              <Fragment>
                {editTableNo === table.TableNo ? (
                <EditableRow 
                  editFormData={editFormData} 
                  handleEditFormChange={handleEditFormChange} 
                  handleCancelClick={handleCancelClick} 
                  />
                ) : (
                <ReadOnlyRow table={table} 
                  handleEditClick={handleEditClick}
                  handleDeleteClick={handleDeleteClick} />
                )}
              </Fragment>
            ))}
          </tbody>
        </table>
      </form>
      {/* <div
        style={{
          color: "white",
          margin: "10px 10px 10px 6px",
        }}
      >
        {JSON.stringify(rows)}
      </div> */}
    </div>
    
  );
}
