import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { CookiesProvider } from "react-cookie";
import { SocketProvider } from "./apiClientContext/SocketProvider";
import { AuthProvider, AuthService } from "react-oauth2-pkce";

const authService = new AuthService({
  clientId:
    process.env.REACT_APP_CLIENT_ID ||
    "713310260478-206l8pqvjvusvdoqh2uodj77ceb56vj3.apps.googleusercontent.com",
  clientSecret:
    process.env.REACT_APP_CLIENT_SECRET ||
    "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",
  location: window.location,
  provider:
    process.env.REACT_APP_PROVIDER ||
    "https://accounts.google.com/o/oauth2/auth",
  tokenEndpoint:
    process.env.REACT_APP_TOKEN_ENDPOINT ||
    "https://polar-lake-54857.herokuapp.com/https://oauth2.googleapis.com/token",
  redirectUri:
    process.env.REACT_APP_REDIRECT_URI ||
    window.location.protocol + "//" + window.location.host, //window.location.origin,
  logoutEndpoint:
    process.env.REACT_APP_LOGOUT_ENDPOINT ||
    "https://www.google.com/accounts/Logout?continue=https://appengine.google.com/_ah/logout?continue=" +
      window.location.protocol +
      "//" +
      window.location.host,
  scopes: ["openid", "profile"],
});

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry(failureCount, error) {
        if (error.status === 404) return false;
        else if (failureCount < 2) return true;
        else return false;
      },
    },
  },
});

export default function AppProviders({ children }) {
  return (
    <QueryClientProvider client={queryClient}>
      <CookiesProvider>
        <AuthProvider authService={authService}>
          <SocketProvider>
            {children}
            <ReactQueryDevtools />
          </SocketProvider>
        </AuthProvider>
      </CookiesProvider>
    </QueryClientProvider>
  );
}
