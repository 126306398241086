import React from "react";

export default function E404() {
  
  return (
    <header className="App-header">
      <h2>Error 404</h2>
      <h3>The requested page does not exist...</h3>
    </header>
  );
}
