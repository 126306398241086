import React, { useCallback, useEffect } from "react";
import { useSocket } from "../apiClientContext/useSocket";

// HOW TO USE WEBSOCKETS FROM FRONTEND
// https://thenable.io/building-a-use-socket-hook-in-react

// HOW TO USE DYNAMODB
// https://stackoverflow.com/questions/70176084/query-dynamodb-using-a-script-in-aws
// https://docs.aws.amazon.com/sdk-for-javascript/v2/developer-guide/dynamodb-example-table-read-write.html
// https://dynobase.dev/dynamodb-pagination/

export default function AppEvents() {
  // TO USE WEBSOCKET CONNECTION
  const socket = useSocket();
  // TO LISTEN FOR WEBSOCKET EVENTS
  const onSocketEvent = useCallback((message) => {
    //const data = JSON.parse(message?.data);
    console.log(message);
    // ... Do something with the data
  }, []);
  useEffect(() => {
    socket.addEventListener("message", onSocketEvent);
    return () => {
      socket.removeEventListener("message", onSocketEvent);
    };
  }, [socket, onSocketEvent]);
  // TO EMIT WEBSOCKET EVENT ON PAGE LOAD
  socket.send(
    JSON.stringify({
      action: "getAllAppEventLogs",
      data: "-",
    })
  );
  return (
    <header className="App-header">
      <h3>App Events</h3>
    </header>
  );
}
