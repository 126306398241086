import React, { useState, Fragment } from "react";
import classes from"./CustQueues.module.css";
import QueueReadOnlyRow from "./QueueReadOnlyRow";
import QueueEditableRow from "./QueueEditableRow";
import userEvent from "@testing-library/user-event";

export default function CustQueues(props) {
  // To get all DB values for Queue 
  const rows = props.items || [];

  // To get all DB values for User
  const user = props.user;
  const userId = user.sub;
  //console.log("userId: " + userId);

  // To get all DB values for Table
  const table = props.table;
  console.log("table: " + table);
  
  const [queues, setQueues] = useState(rows);
  const [tableDropDownVal, setTableDropDownVal] = useState("");
  const [myQueueNo, setMyQueueNo] = useState("");
  //const [statusDropDownVal, setStatusDropDownVal] = useState("");

  //#region handle new row change
  const [addFormData, setAddFormData]=useState({
    QueueNo:'',
    Capacity:'',
    QueueStatus: '',
    TableNo: ''
    //,UserId: ''
  })

  const handleAddFormChange = (event) => {
    event.preventDefault();

    const fieldName = event.target.getAttribute('name');
    const fieldValue = event.target.value;

    const newFormData = { ...addFormData };
    newFormData[fieldName]=fieldValue;

    setAddFormData(newFormData);
  }
  //#endregion

  //#region handle new row submit
  const handleAddFormSubmit = (event) => {
    event.preventDefault();

    const newQueue = {
      QueueNo: addFormData.QueueNo,
      QueueStatus: "PendingTableNo",
      Capacity: addFormData.Capacity,
      TableNo: ""
      //,UserId: userId
    }

    const newQueues = [...queues, newQueue];
    setQueues(newQueues);

    props.wssSendEvent(
      "putQueue",
      {
        QueueNo: addFormData.QueueNo,
        QueueStatus: "PendingTableNo",
        Capacity: addFormData.Capacity,
        TableNo: "",
        UserId: userId,
      },
      true
    );

    setMyQueueNo(addFormData.QueueNo);

  }
  //#endregion

  // handle edit Table row
  const [editQueueNo, setEditQueueNo] = useState(null);

  const handleEditClick = (event, queue) => {
    event.preventDefault();
    setEditQueueNo(queue.QueueNo);

    const formValues = {
      QueueNo: queue.QueueNo,
      Capacity : queue.Capacity,
      QueueStatus : queue.QueueStatus,
      TableNo: queue.TableNo
      //,UserId: queue.UserId
    };

    setEditFormData(formValues);
  };

  const [editFormData, setEditFormData]=useState({
    QueueNo:'',
    Capacity:'',
    QueueStatus: '',
    TableNo: ''
    //,UserId: ''
  })

  const handleEditDropDownFormChange = (event)=>{
    const fieldName = "QueueStatus";
    const fieldValue = event.target.options[event.target.selectedIndex].text;
    //setStatusDropDownVal(event.target.options[event.target.selectedIndex].text);

    // console.log(
    //   "see fieldName: " +
    //   fieldName +
    //     " - fieldValue " +
    //     fieldValue +
    //     " - setStatusDropDownVal " +
    //     statusDropDownVal 
    // );

    const newFormData = {...editFormData};
    newFormData[fieldName] = fieldValue;

    setEditFormData(newFormData);
  }

  const handleEditFormChange = (event)=>{
    event.preventDefault();

    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;
  
    // console.log(
    //   "see fieldName: " +
    //   fieldName +
    //     " - fieldValue " +
    //     fieldValue 
    // );

    const newFormData = {...editFormData};
    newFormData[fieldName] = fieldValue;

    setEditFormData(newFormData);
  }

  const handleEditFormSubmit = (event) => {
    event.preventDefault();
    

    props.wssSendEvent(
      "putQueue",
      {
        QueueNo: editFormData.QueueNo,
        QueueStatus: editFormData.QueueStatus,
        Capacity: editFormData.Capacity,
        TableNo: editFormData.TableNo,
        UserId: userId
      },
      true
    );
    
    if (editFormData.QueueStatus === "Completed"){
      props.wssSendEvent(
        "putTable",
        {
          TableNo: editFormData.TableNo,
          Capacity: editFormData.Capacity,
          TableStatus: "Vacant",
        },
        true
      );
    }else if (editFormData.QueueStatus === "Seated"){
      props.wssSendEvent(
        "putTable",
        {
          TableNo: editFormData.TableNo,
          Capacity: editFormData.Capacity,
          TableStatus: "Occupied",
        },
        true
      );
    }else{
      props.wssSendEvent(
        "putTable",
        {
          TableNo: editFormData.TableNo,
          Capacity: editFormData.Capacity,
          TableStatus: "Assigned",
        },
        true
      );
    }

    const editedQueue = {
      QueueNo: editFormData.QueueNo,
      QueueStatus: editFormData.QueueStatus,
      Capacity: editFormData.Capacity,
      TableNo: editFormData.TableNo
      //,UserId: editFormData.UserId
    }

    const newQueues = [...queues];

    const index = queues.findIndex((queue) => queue.QueueNo === editQueueNo);

    newQueues[index] = editedQueue;

    setQueues(newQueues);
    setEditQueueNo(null);
  }
  //#endregion

  const handleCancelClick = () => {
    setEditQueueNo(null);
  }

  return (
  <React.Fragment>
    {!user.IsStaff && !user.IsAdmin && 
      <div className={classes.tableList}>
        {/* <h2>This is Customer View </h2> */}
        <h3> Get your queue No </h3>
        <form onSubmit={handleAddFormSubmit}>
              <input maxLength="4"
              type="number"
              name="QueueNo"
              required="required"
              placeholder="Queue No"
              onChange={handleAddFormChange}
              />
              <input maxLength="3"
              type="number"
              name="Capacity"
              required="required"
              placeholder="Enter No. Of Pax"
              onChange={handleAddFormChange}
              />
          <button type="submit">Add</button>

          <h3>My Queue No: {myQueueNo}</h3>
          
        </form>

        

        
      </div>
    }
    {(user.IsStaff || user.IsAdmin) && 
      <div className={classes.tableList}>
        {/* <h2>This is Staff View</h2> */}
        <h3> List of Queue Numbers </h3>
        <form onSubmit={handleEditFormSubmit}>
          <table>
            <thead>
              <tr>
                <th>Queue No. </th>
                <th>Capacity</th>
                <th>Queue Status</th>
                <th>Table No</th>
                {/* <th>UserId</th> */}
              </tr>
            </thead>
            <tbody>
              {queues.map((queue) => (
                  <Fragment>
                    {editQueueNo === queue.QueueNo ? (
                    <QueueEditableRow 
                      editFormData={editFormData} 
                      handleEditFormChange={handleEditFormChange} 
                      handleEditDropDownFormChange={handleEditDropDownFormChange} 
                      handleCancelClick={handleCancelClick} 
                      />
                    ) : (
                    <QueueReadOnlyRow queue={queue} 
                      handleEditClick={handleEditClick}
                      />
                    )}
                  </Fragment>
                ))}
            </tbody>
          </table>
        </form>
      </div>
    }
  </React.Fragment>
  );
}
