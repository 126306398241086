import React, { useState } from "react";
import Button from "../UI/Button";
import classes from "./Menu.module.css";

export default function Menu(props) {
  const menuName = props.menuName || "All Items";
  const rows = props.items || [];

  const [itemCat, setItemCat] = useState("");
  const [itemName, setItemName] = useState("");
  const [itemPrice, setItemPrice] = useState("");
  const itemCatChangeHandler = (event) => {
    setItemCat(event.target.value);
  };
  const itemNameChangeHandler = (event) => {
    setItemName(event.target.value);
  };
  const itemPriceChangeHandler = (event) => {
    setItemPrice(event.target.value);
  };
  const addMenuHandler = (event) => {
    event.preventDefault();
    if (itemCat.trim().length === 0 || itemName.trim().length === 0) {
      alert("Item Category and Name are mandatory fields!");
      return;
    }
    if (+itemPrice < 0) {
      alert("Item Price cannot be negative!");
      return;
    }
    console.log(itemCat, itemName, itemPrice);
    props.wssSendEvent(
      "putMenu",
      {
        Menu: itemCat,
        Item: itemName,
        Price: itemPrice,
      },
      true
    );
    setItemCat("");
    setItemName("");
    setItemPrice("");
  };
  const deleteMenuHandler = (Menu, Item) => {
    console.log("Deleting: " + Menu + " - " + Item);
    props.wssSendEvent(
      "deleteMenu",
      {
        Menu: Menu,
        Item: Item,
      },
      true
    );
  };
  return (
    <div
      style={{
        overflow: "auto",
      }}
    >
      <div className={classes.itemsMenu}>
        <table>
          <thead>
            {
              //props.menuName && (
              <tr>
                <th>
                  <h3>{menuName}</h3>
                </th>
              </tr>
              //)
            }
          </thead>
          <tbody>
            {!props.menuName && props.user.IsAdmin && (
              <tr>
                <td colSpan="2">
                  <hr />
                  <form
                    onSubmit={addMenuHandler}
                    style={{
                      margin: "0px 0px 30px 0px",
                    }}
                  >
                    <label htmlFor="ItemCategory">Category&nbsp;</label>
                    <input
                      id="ItemCategory"
                      type="text"
                      style={{ height: "25px", width: "110px" }}
                      value={itemCat}
                      onChange={itemCatChangeHandler}
                    />
                    <br />
                    <label htmlFor="ItemName">Name&nbsp;</label>
                    <input
                      id="ItemName"
                      type="text"
                      style={{ height: "25px", width: "140px" }}
                      value={itemName}
                      onChange={itemNameChangeHandler}
                    />
                    <br />
                    <label htmlFor="ItemPrice">Price&nbsp;</label>
                    <input
                      id="ItemPrice"
                      type="number"
                      style={{ height: "25px", width: "70px" }}
                      value={itemPrice}
                      onChange={itemPriceChangeHandler}
                    />
                    &nbsp;
                    <Button type="submit">Save</Button>
                  </form>
                  <hr />
                </td>
              </tr>
            )}
            {rows.map((m) => (
              <tr key={m.Item}>
                <td>
                  {props.menuName ? m.Item : m.Menu + " - " + m.Item}
                  &nbsp;&nbsp;&nbsp;
                </td>
                <td>${parseFloat(m.Price).toFixed(2)}</td>
                {!props.menuName && props.user.IsAdmin && (
                  <td>
                    <button
                      className="btn"
                      type="button"
                      onClick={() => deleteMenuHandler(m.Menu, m.Item)}
                      style={{
                        background: "red",
                        color: "white",
                        fontWeight: "bold",
                      }}
                    >
                      X
                    </button>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div
        style={{
          color: "white",
          margin: "0px 0px 10px 6px",
        }}
      >
        {JSON.stringify(rows)}
      </div>
    </div>
  );
}
