import React from "react";

export default function E401() {
  
  return (
    <header className="App-header">
      <h2>Error 401</h2>
      <h3>Unauthorised. Page is locked.</h3>
    </header>
  );
}
