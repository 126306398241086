import React from "react";
import "./Home.css";
import { default as ReactAppLogo } from "../assets/ReactAppLogo.svg";
export default function Home() {
  return (
    <header className="App-header">
      <h3>
        Welcome <br />
        HuiChi, Jeanvia & Liran <br />
        to our GCSUS Web frontend!
      </h3>
      <p>
        It is a static website deployed <br />
        on S3 and exposed via CloudFront!
      </p>
      <img src={ReactAppLogo} className="App-logo" alt="ReactAppLogo" />
    </header>
  );
}
