import React, { useState } from "react";
import Checkbox from "../UI/Checkbox";
import classes from "./Users.module.css";
export default function Users(props) {
  let rows = props.items || [];
  const [itemProv, setItemProv] = useState("Google");
  const [itemIden, setItemIden] = useState("");
  const [itemName, setItemName] = useState("");
  const [itemIsAdmin, setItemIsAdmin] = useState(false);
  const [itemIsStaff, setItemIsStaff] = useState(false);
  const itemProvChangeHandler = (event) => {
    console.log(event.target.value);
    setItemProv(event.target.value);
  };
  const itemIdenChangeHandler = (event) => {
    setItemIden(event.target.value);
    var temp = rows.filter((row) => {
      return row.EmailAddress === event.target.value;
    })[0];
    setItemName(temp.Name);
    setItemIsAdmin(temp.IsAdmin);
    setItemIsStaff(temp.IsStaff);
  };
  const itemNameChangeHandler = (event) => {
    setItemName(event.target.value);
    var temp = rows.filter((row) => {
      return row.Name === event.target.value;
    })[0];
    setItemIden(temp.EmailAddress);
    setItemIsAdmin(temp.IsAdmin);
    setItemIsStaff(temp.IsStaff);
  };
  const itemIsAdminChangeHandler = (event) => {
    setItemIsAdmin(!itemIsAdmin);
  };
  const itemIsStaffChangeHandler = (event) => {
    setItemIsStaff(!itemIsStaff);
  };
  const putUserHandler = () => {
    console.log(
      "Put User: " +
        itemProv +
        " - " +
        itemIden +
        " - Admin " +
        itemIsAdmin +
        " - Staff " +
        itemIsStaff
    );
    props.wssSendEvent(
      "putUser",
      {
        IdentityProvider: itemProv,
        EmailAddress: itemIden,
        Name: itemName,
        IsAdmin: itemIsAdmin,
        IsStaff: itemIsStaff,
        IsSelf: false,
      },
      true
    );
    alert("Update Applied!");
  };
  const deleteUserHandler = (IdentityProvider, EmailAddress) => {
    console.log("Deleting User: " + IdentityProvider + " - " + EmailAddress);
    props.wssSendEvent(
      "deleteUser",
      {
        IdentityProvider: IdentityProvider,
        EmailAddress: EmailAddress,
      },
      true
    );
    alert("Deletion Applied!");
  };
  return (
    <div>
      <div className={classes.itemsMenu}>
        <table>
          <thead>
            {
              //props.menuName && (
              <tr key="PageTitle">
                <th colSpan="6">
                  <h3>Manage Users</h3>
                </th>
              </tr>
              //)
            }
          </thead>
          <tbody>
            <tr key="TableForm">
              <td>
                <select
                  value={itemProv}
                  onChange={itemProvChangeHandler}
                  className="form-control"
                  id="itemProvDropdown"
                >
                  <option value="Google">Google</option>
                  {/* <option value="ThisApp">This App</option> */}
                </select>
              </td>
              <td>
                <select
                  value={itemIden}
                  onChange={itemIdenChangeHandler}
                  className="form-control"
                  id="ItemIdenDropdown"
                >
                  {rows.map((n) => (
                    <option value={n.EmailAddress}>
                      {"xxxxx" + n.EmailAddress.slice(-5)}
                    </option>
                  ))}
                </select>
              </td>
              <td>
                <select
                  value={itemName}
                  onChange={itemNameChangeHandler}
                  className="form-control"
                  id="ItemNameDropdown"
                >
                  {rows.map((o) => (
                    <option value={o.Name}>{o.Name}</option>
                  ))}
                </select>
              </td>
              <td>
                <Checkbox
                  label="IsAdmin"
                  isSelected={itemIsAdmin}
                  onCheckboxChange={itemIsAdminChangeHandler}
                  key="IsAdminCheckbox"
                />
              </td>
              <td>
                <Checkbox
                  label="IsStaff"
                  isSelected={itemIsStaff}
                  onCheckboxChange={itemIsStaffChangeHandler}
                  key="IsStaffCheckbox"
                />
              </td>
              <td>
                <button
                  className="btn"
                  type="button"
                  onClick={() => putUserHandler()}
                  style={{
                    background: "blue",
                    color: "white",
                  }}
                >
                  Save
                </button>
              </td>
            </tr>
            <tr key="HrLine1">
              <td colSpan="6">
                <hr />
              </td>
            </tr>
            <tr key="TableColHeaders">
              <td className={classes.tableHeader}>Provider</td>
              <td className={classes.tableHeader}>GoogleId</td>
              <td className={classes.tableHeader}>Name</td>
              <td className={classes.tableHeader}>LastWssConnection</td>
              <td className={classes.tableHeader}>LastLogin</td>
              <td className={classes.tableHeader}>Role</td>
            </tr>
            <tr key="HrLine2">
              <td colSpan="6">
                <hr />
              </td>
            </tr>
            {rows.map((m) => (
              <React.Fragment key={m.IdentityProvider + m.EmailAddress}>
                <tr key={"data-" + m.IdentityProvider + m.EmailAddress}>
                  <td>{m.IdentityProvider} &nbsp;&nbsp;</td>
                  <td>{"xxxxx" + m.EmailAddress.slice(-5)} &nbsp;&nbsp;</td>
                  <td>{m.Name} &nbsp;&nbsp;</td>
                  <td>{m.LastWssConnection} &nbsp;&nbsp;</td>
                  <td>{m.DateTimeUpdated.substring(0, 26)} &nbsp;&nbsp;</td>
                  <td>
                    {m.IsAdmin && <div>Admin </div>}
                    {m.IsStaff && <div>Staff</div>}
                    {!m.IsAdmin && !m.IsStaff && <div>Customer</div>}
                  </td>
                  <td>
                    <button
                      className="btn"
                      type="button"
                      onClick={() =>
                        deleteUserHandler(m.IdentityProvider, m.EmailAddress)
                      }
                      style={{
                        background: "red",
                        color: "white",
                        fontWeight: "bold",
                      }}
                    >
                      X
                    </button>
                  </td>
                </tr>
                <tr key={"hr-" + m.IdentityProvider + m.EmailAddress}>
                  <td colSpan="6">
                    <hr />
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
      <div
        style={{
          color: "white",
          margin: "0px 0px 10px 6px",
        }}
      >
        {JSON.stringify(rows)}
      </div>
    </div>
  );
}
