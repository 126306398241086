import React from "react";

// const QueueReadOnlyRow = ({queue , handleAssignSeatClick, handleUpdateStatusClick }) => {
const QueueReadOnlyRow = ({ queue, handleEditClick }) => {
    return (
        <tr>
            <td>{queue.QueueNo}</td>
            <td>{queue.Capacity}</td>
            <td>{queue.QueueStatus}</td>
            <td>{queue.TableNo}</td>
            <td>
                <button type="button" onClick={(event)=>handleEditClick(event, queue)}> Edit </button>
            </td>
        </tr>
    );
};

export default QueueReadOnlyRow