import React from "react";
import { slide as Slide } from "react-burger-menu";
import { Routes, Route, Link } from "react-router-dom";

const Sidebar = (props) => {
  return (
    <Slide isOpen={props.isOpen}>
      <Routes>
        <Route
          path="/*"
          element={
            <React.Fragment>
              <Link id="home" className="menu-item" to="/">
                Home
              </Link>
              <br />
              <Link id="home" className="menu-item" to="/queues/">
                Queue
              </Link>
              <hr />
              <Link id="orders" className="menu-item" to="/orders/">
                Orders
              </Link>
              <br />
              <Link id="menu" className="menu-item" to="/menu/">
                Menu
              </Link>
              <hr />
              <Link id="tables" className="menu-item" to="/tables/">
                Tables
              </Link>
              <br />
              <Link id="users" className="menu-item" to="/users/">
                Users
              </Link>
              <br />
              <Link id="events" className="menu-item" to="/events/">
                App Events
              </Link>
            </React.Fragment>
          }
        />
        <Route
          path="/menu/*"
          element={
            <React.Fragment>
              <Link id="home" className="menu-item" to="/">
                Home
              </Link>
              <br />
              <Link id="home" className="menu-item" to="/queues/">
                Queue
              </Link>
              <hr />
              <Link id="orders" className="menu-item" to="/orders/">
                Orders
              </Link>
              <br />
              <Link id="menu" className="menu-item" to="/menu/">
                All Items
              </Link>
              <hr />
              {props.menusList.map((m) => (
                <React.Fragment key={m.key}>
                  <Link
                    key={m.key}
                    id={m.key}
                    className="menu-item"
                    to={"/menu/" + m.Menu}
                  >
                    {m.Menu}
                  </Link>
                  <br />
                </React.Fragment>
              ))}
            </React.Fragment>
          }
        />
        <Route
          path="/tables/*"
          element={
            <React.Fragment>
              <Link id="home" className="menu-item" to="/">
                Home
              </Link>
              <br />
              <Link id="home" className="menu-item" to="/queues/">
                Queue
              </Link>
              <hr />
              <Link id="orders" className="menu-item" to="/orders/">
                Orders
              </Link>
              <br />
              <Link id="tables" className="menu-item" to="/tables/">
                All Tables
              </Link>
              <hr />
              <Link id="tab1" className="menu-item" to="/tables/A1">
                Table A1
              </Link>
              <br />
              <Link id="tab2" className="menu-item" to="/tables/A2">
                Table A2
              </Link>
              <br />
              <Link id="tab3" className="menu-item" to="/tables/B1">
                Table B1
              </Link>
              <br />
              <Link id="tab4" className="menu-item" to="/tables/B2">
                Table B2
              </Link>
            </React.Fragment>
          }
        />
        <Route
          path="/orders/*"
          element={
            <React.Fragment>
              <Link id="home" className="menu-item" to="/">
                Home
              </Link>
              <br />
              <hr />
              <Link id="orders" className="menu-item" to="/orders/">
                Orders
              </Link>
              <hr />
              <Link id="tab1" className="menu-item" to="/orders/summary">
                Order History
              </Link>
            </React.Fragment>
          }
        />
      </Routes>
    </Slide>
  );
};

export default Sidebar;
