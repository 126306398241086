import React from "react";

const EditableRow = ({ editFormData, handleEditFormChange, handleCancelClick}) =>
{
    return (
        <tr>
            <td> {editFormData.TableNo} </td>
            <td>
                <input
                    type="text"
                    name="Capacity"
                    required="required"
                    value={editFormData.Capacity}
                    placeholder="Enter table capacity"
                    onChange={handleEditFormChange}
                />
            </td>
            <td> {editFormData.TableStatus} </td>
            <td>
                <button type="submit"> Save </button>
                <button type="button" onClick={handleCancelClick}> Cancel </button>
            </td>
        </tr>
    );
};

export default EditableRow