import React, { useState } from "react";
import Button from "../UI/Button";
import classes from "./Orders.module.css";

export default function Orders(props) {
  const menuName = props.menuName || "Order Menu";
  const rows = props.items || [];
  const user = props.user;
  const queue = props.queue || [];


  const [itemQuantity, setItemQuantity] = useState(""); 

  const itemQuantityChangeHandler = (event) => {
    setItemQuantity(event.target.value);

    console.log("itemQuantity: " + itemQuantity);
  };

  const removeOrderHandler = (event) => {
    console.log(
      "Remove Order: " +
      itemQuantity
    );
    console.log("You just click on Remove Order but it doesnt work ");
  }

  const placeOrderHandler = (event) => {
    console.log(
      "Place Order: " +
      itemQuantity
    );
    console.log("You just click on Place Order but it doesnt work ");
  }

  return (
    <div
      style={{
        overflow: "auto",
      }}
    >
      <div className={classes.itemsMenu}>
        <table>
          <thead>
            {
              //props.menuName && (
              <tr>
                <th>
                  <h3>{menuName}</h3>
                </th>
              </tr>
              //)
            }
          </thead>

          <tbody>
            {rows.map((m) => (
              <tr key={m.Item}>
                <td>
                  {props.menuName ? m.Item : m.Menu + " - " + m.Item}
                  &nbsp;&nbsp;&nbsp;
                </td>
                <td>${parseFloat(m.Price).toFixed(2)}</td>
                <td>Quantity:</td>

                {!props.menuName && !props.user.IsAdmin && !props.user.IsStaff &&(
                <td>
                      <input
                        type="number"
                        name="Quantity"
                        required="required"
                        value={m.itemQuantity} 
                        placeholder="Enter Quantity"
                        onChange={itemQuantityChangeHandler}
                    />
                  </td>
                  )}

                  {!props.menuName && !props.user.IsAdmin && !props.user.IsStaff &&(
                  <td>
                  <button
                      className="btn"
                      type="button"
                      onClick={() =>
                          placeOrderHandler()
                      }
                      >
                      Place Order
                  </button>
                  </td>
                )}


                  {!props.menuName && props.user.IsAdmin && (
                  <td>
                    <button
                      className="btn"
                      type="button"
                      onClick={() =>
                        removeOrderHandler()
                     }
                    >
                      Remove Order
                    </button>
                  </td>
                )}
              </tr>
            ))}

          </tbody>

        </table>
      </div>
      <div
        style={{
          color: "white",
          margin: "0px 0px 10px 6px",
        }}
      >
        {JSON.stringify(rows)}
      </div>
    </div>
  );
}
