import "./App.css";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Sidebar from "./components/Sidebar";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";
import Home from "./components/Home";
import AppEvents from "./components/AppEvents";
import E401 from "./components/UI/E401";
import E404 from "./components/UI/E404";
import Users from "./components/users/Users";
import CustQueues from "./components/custQueues/CustQueues";
import Tables from "./components/tables/Tables";
import Menu from "./components/menu/Menu";
import Orders from "./components/orders/Orders";
import { useSocket } from "./apiClientContext/useSocket";
import { useCookies } from "react-cookie";
import { useAuth } from "react-oauth2-pkce";
import jwtDecode from "jwt-decode";

const ProtectedRoute = ({ isAllowed, redirectPath = "/E401", children }) => {
  if (!isAllowed) {
    return <E401 />;
  }

  return children ? children : <Outlet />;
};

function App() {
  // set authorizationToken cookie to get WSS access
  const [cookies, setCookie] = useCookies();
  // Check device width to determine if Sidebar remains open or closes.
  const [width, setWidth] = useState(window.innerWidth);
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  // OAuth2.0
  const { authService } = useAuth();
  const login = async () => authService.authorize();
  const logout = async () => authService.logout(true);
  const token = authService.getAuthTokens();
  //console.log("Access Token : " + JSON.stringify(token));
  const user = useRef({});

  const socket = useSocket();
  // data loading variables
  const [, updateState] = React.useState();
  const menuItemsRef = useRef([]);
  const menusRef = useRef([]);
  const firstLoadRef = useRef(true);
  const tableRef = useRef([]);
  const queueRef = useRef([]);
  const usersRef = useRef([]);
  const orderRef = useRef([]);
  // WSS Send Event
  const wssSendEvent = (eventName, dataObj, isToRefresh = false) => {
    socket.send(
      JSON.stringify({
        action: eventName,
        data: dataObj,
      })
    );
    //console.log("Raised event: " + eventName);
    if (isToRefresh) {
      setTimeout(function () {
        //code to be executed after a time delay in milliseconds
        firstLoadRef.current = true;
        wssInitialise();
        updateState({});
      }, 1500);
    }
  };
  // WAIT FOR WSS CONNECTION
  const wssInitialise = () => {
    if (firstLoadRef.current) {
      ///* IF GOOGLE AUTHENTICATED
      if (authService.isAuthenticated()) {
        console.log(token);
        user.current = jwtDecode(token.id_token); // decode the access token here
        console.log("USER : " + JSON.stringify(user.current));
        fetch(
          "https://polar-lake-54857.herokuapp.com/https://oezwtkntb5.execute-api.ap-southeast-1.amazonaws.com/prod/auth",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ GoogleIdToken: token.id_token }),
          }
        )
          .then(function (response) {
            if (response.status !== 200) {
              setCookie("wssToken", "", {
                path: "/",
              });
              usersRef.current = [];
              user.current = {};
              queueRef.current = [];
              tableRef.current = [];
              orderRef.current = [];
              logout();
            }
            return response.text();
          })
          .then(function (data) {
            console.log(data); // this will be a string
            setCookie("wssToken", JSON.parse(data)["wssToken"], {
              path: "/",
            });
          });
      } //*/

      console.log("Initialising sidebar by fetching menu items...");
      if (socket.readyState === WebSocket.OPEN) {
        // TO EMIT WEBSOCKET EVENT ON PAGE LOAD
        socket.send(
          JSON.stringify({
            action: "getAllUsers",
            data: "-",
          })
        );
        socket.send(
          JSON.stringify({
            action: "getAllMenus",
            data: "-",
          })
        );
        socket.send(
          JSON.stringify({
            action: "getTables",
            data: "-",
          })
        );
        socket.send(
          JSON.stringify({
            action: "getAllQueue",
            data: "-",
          })
        );
        socket.send(
          JSON.stringify({
            action: "getAllOrder",
            data: "-",
          })
        );
        firstLoadRef.current = false;
      } else if (socket.readyState === WebSocket.CONNECTING) {
        socket.addEventListener("open", () => wssInitialise());
      } else {
        firstLoadRef.current = false;
        console.log(
          "WSS was neither OPEN nor CONNECTING. Backend Data was not fetched."
        );
        window.location.reload();
      }
    }
  };
  // TO LISTEN FOR WEBSOCKET EVENTS
  const onSocketEvent = useCallback((message) => {
    const data = JSON.parse(message?.data);
    if ("AllMenuItems" in data) {
      menuItemsRef.current = data["AllMenuItems"].sort(function (a, b) {
        return (
          +(a.Menu > b.Menu) ||
          +(a.Menu === b.Menu) - 1 ||
          +(a.Item > b.Item) ||
          +(a.Item === b.Item) - 1
        );
      });
      menusRef.current = [];
      for (let i = 0; i < menuItemsRef.current.length; i++) {
        if (i > 0) {
          if (
            menuItemsRef.current[i]["Menu"] !==
            menuItemsRef.current[i - 1]["Menu"]
          ) {
            menusRef.current.push({
              key: "menu" + String(i),
              Menu: menuItemsRef.current[i]["Menu"],
              Items: [],
            });
          }
        } else {
          menusRef.current.push({
            key: "menu" + String(i),
            Menu: menuItemsRef.current[i]["Menu"],
            Items: [],
          });
        }
        menusRef.current[menusRef.current.length - 1]["Items"].push({
          ...menuItemsRef.current[i],
          key:
            menuItemsRef.current[i]["Menu"] +
            " - " +
            menuItemsRef.current[i]["Item"],
        });
      }
      //console.log(menuItemsRef.current);
      //console.log(menusRef.current);
    }
    if ("AllTables" in data) {
      //console.log(data["AllTables"]);
      tableRef.current = data["AllTables"].sort(function (a, b) {
        return a.TableNo - b.TableNo;
      });
      //console.log(tableRef.current);
    }
    if ("AllQueueNo" in data) {
      //console.log(data["AllQueueNo"]);
      queueRef.current = data["AllQueueNo"].sort(function (a, b) {
        return a.QueueNo > b.QueueNo ? 1 : -1;
      });
      let queueSubArray = queueRef.current.map((a) => a.userId);
      if (queueSubArray.includes(user.current.sub)) {
        let objIndex = queueRef.current.findIndex(
          (obj) => obj.userId === user.current.sub
        );
      }
      //console.log(queueRef.current);
    }
    if ("AllOrders" in data) {
      //console.log(data["AllOrders"]);
      orderRef.current = data["AllOrders"].sort(function (a, b) {
        return a.QueueNo > b.QueueNo ? 1 : -1;
      });
      //console.log(orderRef.current);
    }
    if ("AllUsers" in data) {
      //console.log(data["AllUsers"]);
      usersRef.current = data["AllUsers"].sort(function (a, b) {
        return a.DateTimeUpdated > b.DateTimeUpdated ? 1 : -1;
      });
      if (authService.isAuthenticated() && usersRef.current !== []) {
        let googleSubArray = usersRef.current.map((a) => a.EmailAddress);
        if (googleSubArray.includes(user.current.sub)) {
          let objIndex = usersRef.current.findIndex(
            (obj) => obj.EmailAddress === user.current.sub
          );
          //console.log(objIndex);
          //console.log(usersRef.current);
          user.current.IsAdmin = usersRef.current[objIndex].IsAdmin;
          user.current.IsStaff = usersRef.current[objIndex].IsStaff;
          wssSendEvent(
            "putUser",
            {
              EmailAddress: user.current.sub,
              IdentityProvider: "Google",
              PasswordHash: "---",
              Salt: "---",
              Name: user.current.name,
              IsAdmin: usersRef.current[objIndex].IsAdmin,
              IsStaff: usersRef.current[objIndex].IsStaff,
              IsSelf: true,
            },
            false
          );
        } else {
          user.current.IsAdmin = false;
          user.current.IsStaff = false;
          wssSendEvent(
            "putUser",
            {
              EmailAddress: user.current.sub,
              IdentityProvider: "Google",
              PasswordHash: "---",
              Salt: "---",
              Name: user.current.name,
              IsAdmin: false,
              IsStaff: false,
              IsSelf: true,
            },
            false
          );
        }
      }
    }
    updateState({});
  }, []);
  useEffect(() => {
    socket.addEventListener("message", onSocketEvent);
    return () => {
      socket.removeEventListener("message", onSocketEvent);
    };
  }, [socket, onSocketEvent]);

  if (firstLoadRef.current) {
    wssInitialise();
  }

  return (
    <div className="App">
      <BrowserRouter>
        {width <= 768 ? (
          <Sidebar isOpen={false} menusList={menusRef.current} />
        ) : (
          <Sidebar isOpen={true} menusList={menusRef.current} />
        )}
        <div
          style={{
            backgroundColor: "black",
            color: "white",
            height: 90,
            width: "100%",
            textAlign: "right",
          }}
        >
          <div
            style={{
              paddingTop: 15,
              paddingRight: 20,
            }}
          >
            {authService.isPending() && (
              <React.Fragment>
                Loading...
                <button
                  onClick={() => {
                    setCookie("wssToken", "", {
                      path: "/",
                    });
                    usersRef.current = [];
                    user.current = {};
                    queueRef.current = [];
                    tableRef.current = [];
                    logout();
                  }}
                >
                  Reset
                </button>
              </React.Fragment>
            )}
            {!authService.isPending() && !authService.isAuthenticated() && (
              <React.Fragment>
                Not Logged in yet &nbsp;
                <button
                  onClick={() => {
                    login();
                  }}
                >
                  Google Login
                </button>
              </React.Fragment>
            )}
            {!authService.isPending() && authService.isAuthenticated() && (
              <React.Fragment>
                {user.current.IsAdmin && <div>Admin </div>}
                {user.current.IsStaff && <div>Staff</div>}
                {!user.current.IsAdmin && !user.current.IsStaff && (
                  <div>Customer</div>
                )}{" "}
                &nbsp;
                {user.current.name} &nbsp;
                <button
                  onClick={() => {
                    setCookie("wssToken", "", {
                      path: "/",
                    });
                    usersRef.current = [];
                    user.current = {};
                    queueRef.current = [];
                    tableRef.current = [];
                    logout();
                  }}
                >
                  Google Logout
                </button>
              </React.Fragment>
            )}
          </div>
        </div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            path="/queues/*"
            element={
              <CustQueues
                items={queueRef.current}
                user={user.current}
                table={tableRef.current}
                wssSendEvent={wssSendEvent}
              />
            }
          />
          <Route element={<ProtectedRoute isAllowed={user.current.IsAdmin} />}>
            <Route path="/events" element={<AppEvents />} />
            <Route
              path="/users/*"
              element={
                <Users items={usersRef.current} wssSendEvent={wssSendEvent} />
              }
            />
          </Route>
          <Route
            element={
              <ProtectedRoute
                isAllowed={user.current.IsAdmin || user.current.IsStaff}
              />
            }
          >
            <Route
              path="/tables/*"
              element={
                <Tables items={tableRef.current} wssSendEvent={wssSendEvent} />
              }
            />
            <Route path="/tables/:tableId" element={<Tables />} />
          </Route>
          
          <Route 
              path="/orders/*"
              element={
                <Orders
                  user={user.current}
                  items={menuItemsRef.current}
                  order={orderRef.current}
                  queue={queueRef.current}
                  wssSendEvent={wssSendEvent}
                />
              }
            />
          

          <Route
            path="/menu/*"
            element={
              <Menu
                user={user.current}
                items={menuItemsRef.current}
                wssSendEvent={wssSendEvent}
              />
            }
          />
          {menusRef.current.map((m) => (
            <Route
              key={m.key}
              path={"/menu/" + m.Menu}
              element={<Menu items={m.Items} menuName={m.Menu} />}
            />
          ))}

          <Route path="/E401" element={<E401 />} />
          <Route path="*" element={<E404 />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
