import React from "react";

const ReadOnlyRow = ({ table, handleEditClick, handleDeleteClick }) => {
    return (
        <tr>
            <td>{table.TableNo}</td>
            <td>{table.Capacity}</td>
            <td>{table.TableStatus}</td>
            <td>
                <button type="button" onClick={(event)=>handleEditClick(event, table)}> Edit </button>
                <button type="button" onClick={()=>handleDeleteClick(table.TableNo)}> Delete </button>
            </td>
        </tr>
    );
};

export default ReadOnlyRow