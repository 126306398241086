import { useEffect, useState, createContext } from "react";
import Cookies from "js-cookie";

const SOCKET_URL =
  "wss://9u1px91fu9.execute-api.ap-southeast-1.amazonaws.com/prod";
//"ws://localhost:3001";
const SOCKET_RECONNECTION_TIMEOUT = 1200; // milliseconds

// use authorizationToken cookie to get WSS access
console.log(
  "WSS Token : " + Cookies.get("wssToken") || "No WSS token currently."
);
const webSocket = new WebSocket(
  SOCKET_URL + "/?Auth=" + Cookies.get("wssToken")
);

export const SocketContext = createContext(webSocket);

export const SocketProvider = (props) => {
  const [ws, setWs] = useState(webSocket);

  useEffect(() => {
    const onClose = () => {
      console.log(
        "WSS Token : " + Cookies.get("wssToken") || "No WSS token currently."
      );
      if (
        Cookies.get("wssToken") !== undefined &&
        Cookies.get("wssToken").length > 60
      ) {
        setTimeout(() => {
          setWs(
            new WebSocket(SOCKET_URL + "/?Auth=" + Cookies.get("wssToken"))
          );
        }, SOCKET_RECONNECTION_TIMEOUT);
      }
    };

    ws.addEventListener("close", onClose);

    return () => {
      ws.removeEventListener("close", onClose);
    };
  }, [ws, setWs]);

  return (
    <SocketContext.Provider value={ws}>{props.children}</SocketContext.Provider>
  );
};
